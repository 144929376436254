import * as React from 'react'
import Constants from '../../common/constants'
import { Stack, Box, Container, Link, Flex } from '@chakra-ui/react'
import { SvgLogo as Logo } from '../../common/SvgLogo'

export const FooterLinks = (props) => {
  const baseProps = {
    color: 'white',
    fontWeight: '500',
    py: { base: '2', md: '0' },
    _hover: {
      color: 'gray.300'
    }
  }

  const linksProps = { ...baseProps, ...props }

  return (
    <>
      <Link
        color='white'
        href={Constants.doorbell.supportEmailMailTo}
        isExternal
        {...linksProps}
      >
        {Constants.doorbell.supportEmail}
      </Link>
      <Link
        href={Constants.doorbell.privacyPolicyLink}
        data-test='privacy-link'
        isExternal
        {...linksProps}
      >
        {Constants.doorbell.privacyPolicy}
      </Link>
      <Link
        href={Constants.doorbell.termsOfServiceLink}
        data-test='terms-link'
        isExternal
        {...linksProps}
      >
        {Constants.doorbell.termsOfService}
      </Link>
    </>
  )
}

const Footer = (props) => {
  return (
    <Box
      as='footer'
      role='contentinfo'
      bg='landingPage.black'
      data-test='landing-page-footer'
      {...props}
    >
      <Container maxW='5xl'>
        <Flex
          justify={{ base: 'center', md: 'space-between' }}
          align='center'
          h={{ base: '84px', md: '96px' }}
        >
          <Logo color='white' sx={{ height: '36px' }} />
          <Stack
            direction={{ base: 'column', md: 'row' }}
            w={{ base: '100%', md: 'initial' }}
            spacing={{ base: '4', md: '8' }}
            display={{ base: 'none', md: 'flex' }}
          >
            <FooterLinks />
          </Stack>
        </Flex>
      </Container>
    </Box>
  )
}

export default Footer
